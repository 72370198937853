<template>
	<div style="width:100%;height:100%;">
		<el-container style="height:100%;" direction="vertical">
			<Header></Header>
			<el-container>
				<el-aside class="leftNavi">
					<el-menu
						class="el-menu-vertical-demo"
						:style="{'height':height}"
					    :default-active="selectMenu"
					    :collapse="isCollapse"
					    :router="true"
						:unique-opened="true"
					    :collapse-transition="true"
						active-text-color="#409EFF"
					    @select="selectMenuBtn"
					>	
						<template v-for="item in leftMenu">
							<el-sub-menu v-if="item.children.length>0" :index="item.key">
								<template #title>
									<Icon :icon="item.icon"></Icon>
									<span>{{item.name}}</span>
								</template>
								<el-menu-item v-for="subItem in item.children" :index="subItem.key">{{subItem.name}}</el-menu-item>
							</el-sub-menu>
							<el-menu-item v-else :index="item.key">
								<Icon :icon="item.icon"></Icon>
								<span>{{item.name}}</span>
							</el-menu-item>
						</template>
					</el-menu>
				</el-aside>
				<el-main style="padding:5px;">
					<div style="display: flex;border-bottom:1px solid #E1E1E1;padding:10px 0px 15px 10px;">
						<div style="cursor:pointer;" @click="isCollapse = !isCollapse">
							<Icon icon="expand" v-if="isCollapse == true"></Icon>
							<Icon icon="fold" v-else></Icon>
						</div>
						<div style="padding-left:10px;">
							<el-breadcrumb separator-class="el-icon-arrow-right">
								<el-breadcrumb-item v-for="item in selectName">{{item}}</el-breadcrumb-item>
							</el-breadcrumb>
						</div>
					</div>
					<router-view/>
				</el-main>
			</el-container>
		</el-container>  
	</div>	
</template>
<script>
	import Header from '@/components/AdminHeader'
	import Icon from '@/components/Icon'
	import gloableLeftMenu from '../../assets/js/leftMenu.js'
	export default{
	  components:{Header,Icon},
		data(){
			return{
				height: '',
				isCollapse: false,
				leftMenu:gloableLeftMenu.leftMenu,
				selectMenu: '/Main/Setting/Admin',
				selectName:[],
			}
		},
		created(){
		  // let selectMenu = window.localStorage.getItem("selectMenu");
		  // let selectName = window.localStorage.getItem("selectName").split(",");
		  // if(selectMenu == null || selectMenu == undefined){
		  //   this.selectMenu = "/Main/Setting/Role";
		  //   this.selectName = ["基础设置",'角色管理'];
		  // }else{
		  //   this.selectMenu = selectMenu;
		  //   this.selectName = selectName;
		  // }
		  // this.$router.push({
		  //   path: selectMenu,
		  //   query: {
		  //     t: new Date().getTime()
		  //   }
		  // })
		},
		methods:{
			selectMenuBtn(index,indexPath){
				let selectMenu = "";
				let selectName = "";
				let rows = this.leftMenu;
				for(var i=0;i<rows.length;i++){
					let row = rows[i].children;
					for(var j=0;j<row.length;j++){
						if(index == row[j].key){
							selectMenu = row[j].key;
							selectName = row[j].title.split("/");
							break
						}
					}
				}
				this.selectMenu = selectMenu;
				this.selectName = selectName;
				this.$router.push({
					path: selectMenu,
					query: {
						t: new Date().getTime()
					}
				})
				window.localStorage.setItem("selectMenu",selectMenu);
				window.localStorage.setItem("selectName",selectName);
			},
			setHeight(){
				let h = document.documentElement.clientHeight || document.body.clientHeight;
				this.height = h - 70 + 'px';
			}
		},
		beforeMount(){
		  this.setHeight();
		},
		mounted(){
		  let that = this;
		  window.addEventListener('resize',that.setHeight(),false);
		},
		beforeDestroy(){
		  window.removeEventListener('resize',this.setHeight(),false)
		}
	}
</script>

<style scoped>
	.el-menu-vertical-demo:not(.el-menu--collapse) {
		width: 200px;
		min-height: 400px;
	}
	.el-aside{
		--el-aside-width: ""
	}
</style>
