const leftMenu = [
	{
		key: '/Setting/Basic',
		title: '基本信息/',
		name:'基本信息',
		icon: 'User',
		children:[]
	},
	{
		key: '/Setting/Give',
		title: '赠送记录/',
		name:'赠送记录',
		icon: 'Link',
		children:[]
	},
	{
		key: '/Setting/Address',
		title: '收货地址/',
		name:'收货地址',
		icon: 'AddLocation',
		children:[]
	},
	{
		key: '/Setting/Bank',
		title: '银行卡/',
		name:'银行卡',
		icon: 'Wallet',
		children:[]
	},
	{
		key: '/Setting/Secure',
		title: '安全/',
		name:'安全',
		icon: 'View',
		children:[
			{
				key: '/Setting/Secure/LoginPass',
				title: '安全/登录密码',
				name:'登录密码',
				icon: ''
			},
			{
				key: '/Setting/Secure/PayPass',
				title: '安全/交易密码',
				name:'交易密码',
				icon: ''
			}
		]
	},
]
export default {
  leftMenu
}