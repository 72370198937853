<template>
  <div class="transfer">
    <el-header class="mainHeader" height="70px">
        <div class="logo">
          <img src="@/assets/img/logoTop.png" style="height:50px;">
        </div>
        <div style="flex:1;"></div>
        <div style="padding-top:8px;">
			<el-dropdown @command="handleCommand">
				<span class="el-dropdown-link">
					<el-avatar :size="50" :src="avantaUrl"></el-avatar>
					<span class="name">{{anName}}</span>
				</span>
				<template #dropdown>
					<el-dropdown-menu>
						<el-dropdown-item command="2"><el-icon><Edit /></el-icon>修改密码</el-dropdown-item>
						<el-dropdown-item command="1"><el-icon><Plus /></el-icon>退出登录</el-dropdown-item>
					</el-dropdown-menu>
				</template>
			</el-dropdown>
        </div>
    </el-header>
    <el-dialog
      width="400px"
      title="修改密码"
      v-model="dialogFormVisible"
	  style="padding:0px;"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
	  destroy-on-close
      @close="cancelWin"
    >
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="初始密码" prop="oldPass">
          <el-input type="password" v-model="ruleForm.oldPass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="anPassword">
          <el-input type="password" v-model="ruleForm.anPassword" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="comPassword">
          <el-input type="password" v-model="ruleForm.comPassword" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
	  <template #footer>
		  <div class="dialog-footer">
			<el-button type="primary" @click="submitForm('ruleForm')" :loading="loading">保存</el-button>
		  </div>
	  </template>
    </el-dialog>
  </div>
</template>

<script>
  import avantaUrl from "../assets/img/avanta.png"
  import { Edit, Plus } from '@element-plus/icons-vue'
  export default{
	components:{Edit,Plus},
    data() {
		const validatePass2 = (rule, value, callback) => {
		  if (value === '') {
			callback(new Error('请输入确认密码!'))
		  } else if (value !== this.ruleForm.anPassword) {
			callback(new Error("两次密码输入 不一致"))
		  } else {
			callback()
		  }
		}
		return {
			anName:'',
			batId: '',
			avantaUrl:avantaUrl,
			dialogFormVisible:false,
			loading:false,
			formLabelWidth: '120px',
			rules: {
				oldPass: [
					{ required: true, message: '初始密码不能为空', trigger: 'change' }
				],
				anPassword: [
					{ required: true, message: '密码不能为空', trigger: 'change' }
				],
				comPassword: [
					{ required: true, validator: validatePass2, trigger: 'change' }
				]
			},
			ruleForm:{
				oldPass:'',
				anPassword:'',
				anId:localStorage.getItem("anId")
			},
		}
    },
    mounted() {
      let anName = localStorage.getItem("anName");
      let anId = localStorage.getItem("anId");
      if(anId==null||anId==undefined||anId==''){
         //this.$router.push("/Login");
      }else{
        let selectedMenu = this.$route.path;
        this.anName = anName;
        this.anId = anId;
        this.selectMenu = selectedMenu;
      }
    },
    methods: {
      submitForm(formName) {
        let that = this;
        that.$refs[formName].validate((valid) => {
          if (valid) {
            that.loading = true;
            that.$axios({
            	method:'post',
            	url:'sys/Sys_Admin/setPassword.action',
            	data:this.$qs.stringify(this.ruleForm)
            })
            .then((res)=>{
            	let msg = res.data.msg;
            	if(msg==2){
					this.$router.push("/Login");
            	}else{
            		this.$message.error(msg);
            	}
				that.loading = false;
            })
            .catch((err)=>console.log('请求失败',err))
          } else {
			return false;
          }
        });
      },
      handleCommand(index){
        if(index == '1'){
			localStorage.removeItem("anId");
			localStorage.removeItem("anName");
			localStorage.removeItem("path");
			localStorage.removeItem("selectName");
			this.$router.push("/Login");
        }
        if(index == '2'){
			this.dialogFormVisible = true;
        }
      },
      cancelWin(){
        this.$refs.ruleForm.resetFields();
      }
    }
  }
</script>

<style scoped>
	.mainHeader{
		background: #409EFF;
		padding-left:10px;
		display:flex;
		border-bottom:1px solid rgb(225, 225, 225);
	}
	.logo{
		display:inline-block;
		padding-top:10px;
	}
	.logo .Img{
		width: 50px;
		height: 50px;
		float: left;
		padding-top: 10px;
	}
	.logo .text{
		width: 310px;
		height: 70px;
		line-height: 70px;
	}
	.name{
		line-height:50px;
		font-size:18px;
		color:white;
		font-weight:bold;
		margin-left:10px;
		float:right;
	}
	.el-dropdown{
		cursor:pointer;
	}
	.el-dropdown-menu__item{
		width:150px !important;
	}
</style>
